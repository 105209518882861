import { Link } from 'react-router-dom'
import React from 'react'

const Tile = ({ data, type }) => {

    const push_to_local_storage = (data) => { localStorage.setItem("userinfo", JSON.stringify(data)) }
    
    return (
        <div className='flex justify-between items-center bg-purple-200 p-4 rounded-lg my-3 border border-purple-300 rounded-lg'>

            <h1>Name: {data.FirstName + ' ' + data.MiddleName + ' ' + data.LastName}</h1>
            <h1>City: {data.City === "" ? "NA" : data.City}</h1>
            <h1>Pin Code: {data.Pincode === "" ? "NA" : data.Pincode}</h1>
            <h1> Village: {data.NativeVillage === "" ? "NA" : data.NativeVillage} </h1>
            { type === "update" ? (
                <div>
                    <Link onClick={(event) => {push_to_local_storage(data)}} target="_blank" rel="noopener noreferrer" to={`/info/${data.ApplicationID}`} state={{ "userinfo": data }} className='bg-purple-900 p-2 rounded-lg text-white text-center'>
                        INFO
                    </Link>
                </div>
            ) :(
                <div>
                    <Link onClick={(event) => {push_to_local_storage(data)}} target="_blank" rel="noopener noreferrer" to={`/bucket/info/${data.recid}`} state={{ "bucketinfo": data }} className='bg-purple-900 p-2 rounded-lg text-white text-center'>
                        INFO
                    </Link>
                </div>
            )}
            
        </div>
    )
}

export default Tile